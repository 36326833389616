<template>
    <fragment>
        <div :style="{ borderBottom: '1px solid #E9E9E9',margin:'0 0 10px' }">
            <a-checkbox
                    :style="{ margin:'0 0 10px' }"
                    v-model:checked="checkAll"
                    :indeterminate="indeterminate"
                    @change="onCheckAllChange"
            >
                全选
            </a-checkbox>
        </div>

        <a-checkbox-group v-model:value="checkedList" @change="emitData" >
            <a-row>
                <a-col :style="{ margin:'0 0 10px' }"  v-for="(checkbox,checkboxIndex) in structure.config.values" :key="checkboxIndex">
                    <a-checkbox :value="checkbox.value">
                        {{checkbox.label}}
                    </a-checkbox>
                </a-col>
            </a-row>
        </a-checkbox-group>
        <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>

    </fragment>

</template>
<script>
    export default {
        name: 'LuckyCheckbox',
        props: ['structure','showWarning'],
        data() {
            return {
                checkedList: this.structure.value,
                indeterminate: false,
                checkAll: false,
                plainOptions:[]
            };
        },
        mounted() {
            let that = this
            that.structure.config.values.forEach((items,index)=>{
                that.plainOptions[index] = items.value
            })
            if(Object.keys(that.structure.value).length == Object.keys(that.structure.config.values).length)
            {
                that.indeterminate = false
                that.checkAll = true
            }

        },
        methods: {
            emitData(checkedList){
                this.indeterminate = !!checkedList.length && checkedList.length < this.plainOptions.length;
                this.checkAll = checkedList.length === this.plainOptions.length;
                this.structure.value = checkedList;
            },
            onCheckAllChange(e) {
                Object.assign(this, {
                    checkedList: e.target.checked ? this.plainOptions : [],
                    indeterminate: false,
                });
                this.structure.value = this.checkedList;
            },
        },
    };
</script>
