import Vue from 'vue'
import { LuckyForm, LuckyTable,LuckyLayout } from "@/components/page-components";
Vue.component("LuckyForm",LuckyForm)
Vue.component("LuckyTable",LuckyTable)
Vue.component("LuckyLayout",LuckyLayout)
const loadingInstance = '';
const loadingNumber = 0;
const loadingTime = 0;



//接口地址
const apiURL = 'https://sjyh.zhqkg.com';
//个性化页面
import { LuckyIndex } from '@/components/other-components/volunteer'
Vue.component("LuckyIndex",LuckyIndex)


export default {
    apiURL,loadingInstance,loadingNumber,loadingTime
}