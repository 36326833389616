<template>
    <fragment>
        <a-textarea
                v-model:value="value"
                allow-clear
                :rows="4"
        />
        <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>

    </fragment>
</template>

<script>
    export default {
        name: 'LuckyTextarea',

        props: ['structure', 'showWarning'],
        data() {
            return {
                value: ''
            }
        },
        watch: {
            value(newValue) {
                this.structure.value = newValue
            }
        },
        mounted() {
            this.value = this.$changeHtml(this.structure.value)
        },
    }
</script>

<style scoped>

</style>