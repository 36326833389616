<template>
    <fragment>
        <a-input
                size="large"
                v-model:value="value"
                allow-clear
                :disabled="show"
        />
        <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>
    </fragment>
</template>

<script>
export default {
    name: 'LuckyInput',

    props: ['structure', 'showWarning'],
    data() {
        return {
            show: false,
            value: ''
        }
    },
    watch: {
        value(newValue) {
            this.structure.value = newValue
        }
    },
    mounted() {
        this.value = this.$changeHtml(this.structure.value)
        if (this.structure.hasOwnProperty('config') &&
                this.structure.config.hasOwnProperty('disabled'))
            this.show = this.structure.config.disabled
    },

}
</script>

<style scoped>

</style>