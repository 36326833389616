<template>
    <fragment>

        <el-button style="margin-left: 0;margin-right: 10px" type="info" v-if="isCollapse" size="small" @click="clear">清空内容</el-button>
        <el-button style="margin-left: 0;margin-right: 10px" :type="isCollapse?'danger':'primary'" size="small" @click="collapse">{{collapseMsg}}</el-button>
        <span class="warning-class" >{{structure.warning?structure.warning:"如果内容需要上传图片,请确保图片后缀为小写字母"}}</span>

        <tinymce_editor :style="{width:structure.config.width+'px',marginTop:'10px'}"
                        ref="editor"
                        v-if="isCollapse"
                        :field="structure.field"
                        v-model="value"
                        :base-url="baseUrl"
                        :language="language"
                        :skin="skin">
        </tinymce_editor>

    </fragment>
</template>

<script>
    import tinymce_editor from '@/components/form-components/tinymce_editor'
    import _$ from 'jquery'
    export default {
        name: 'LuckyEditor',

        components: {
            tinymce_editor
        },
        props: ['structure'],
        data () {
            return {
                baseUrl: '',
                language: 'zh_CN',
                skin: 'oxide',
                isCollapse:false,
                collapseMsg:"查看/编辑内容",
                value: this.$changeHtml(this.structure.value)
            }
        },
        //观察value的变化
        watch: {
            value (newValue) {
                this.structure.value = newValue;
            },
        },
        created() {

        },
        methods: {
            // 清空内容
            clear () {
                this.$refs.editor.clear()
            },
            //展开收起富文本编辑器
            collapse(){
                if(this.isCollapse){
                    let toolbar = document.getElementsByClassName("tox-tbtn--enabled");
                    if(toolbar){
                        _$('.tox-tbtn--enabled').click()
                    }
                    this.isCollapse = false;
                    this.collapseMsg = "查看/编辑内容"

                }else{
                    this.isCollapse = true;
                    this.collapseMsg = "关闭编辑器"

                }
            },
        }
    }
</script>

