<template>

    <a-rate v-model="rate" :count="parseInt(5)" @change="change" :disabled="structure.config.disabled"/>

</template>

<script>
export default {
    name: 'LuckyTableRate',

    props: ['id', 'value', 'structure'],
    data() {
        return {
            rate: 0
        }
    },
    mounted() {
        this.rate = parseInt(this.value)
    },

    methods: {
        change() {
            let that = this
            let obj = {
                'change_field': that.structure.field,
                'change_value': this.rate,
                'id': this.id,
            }
            let handleInfo = {
                handleType: 'request',
                requestUrl: that.structure.config.requestUrl
            }
            that.$parent.$parent.$parent.tableHandles(handleInfo, obj)
        },
    }
}
</script>

<style scoped>

</style>