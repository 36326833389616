<template>
  <fragment>
    <a-row class="buttons-class">
      <el-button size="small" type="info" v-if="showCloseButton" @click="closeComponent()">返回</el-button>
      <a-breadcrumb :style="{paddingLeft:'10px',height:'32px',lineHeight:'32px',float:'right',marginRight:'20px'}">
        <a-breadcrumb-item v-for="(item,index) in breadCrumb" :key="index">{{ item }}</a-breadcrumb-item>
      </a-breadcrumb>
    </a-row>
    <a-row style="margin-top: 20px">
      <div class="chat-page-container">
        <div ref="msgList" id="msg-list" class="msg-list">
          <div
                  v-for="(item, index) in dataList"
                  :key="index"
                  :isSlef="item.isSelf"
                  :style="{
          order: item.createTime,
        }"
                  class="msg-item"
          >
            <img
                    alt=""
                    class="avatar"
                    :src="item.portrait"
            />
            <div class="content">
              <span class="nickname">{{ item.nickname }}</span>
              <p class="text">
                {{ item.content }}
              </p>
            </div>
          </div>
        </div>


      </div>
    </a-row>
    <div class="textarea" >
      <textarea :autocapitalize="false" placeholder="请输入要回复的内容" v-model="replay_content"></textarea>
    </div>
    <div style="float: left;height: 90px;line-height: 90px;margin-left: 50px">
      <el-button  type="primary" @click="replay">发送</el-button>
    </div>

  </fragment>
</template>

<script>

  export default {
    name:'LuckyChat',
    props: ['componentData','componentIndex','componentStatus','breadCrumb'],
    data() {
      return {
        interval: null,
        dataList: [],
        timer:'',
        roundNumber:'',
        replay_content:'',
        showCloseButton:false   //是否显示返回按钮
      };
    },
    mounted() {
      let that = this
      //是否展示返回按钮
      that.showCloseButton = that.componentIndex ? true : false ;

      //判断是否是从其他页面跳转过来，并带来参数
      let obj;
      if(that.componentData.hasOwnProperty('obj')){
        obj = that.componentData.obj;
      }

      that.$ajax.post(that.componentData.requestUrl, obj)
              .then(res=>{
                that.concatNewMsgList(res);
              })

      this.interval = setInterval(() => {
        that.update_chat();

      }, 5000);
      setTimeout(() => {
        that.scrollToBottom()

      }, 1000);

    },
    unmounted() {
      if (this.interval) clearInterval(this.interval);
    },
    methods: {
      replay(){
        let that = this
        let obj;
        if(that.componentData.hasOwnProperty('obj')){
          obj = that.componentData.obj;
        }
        if(that.replay_content.length > 0){
          obj.content = that.replay_content
            that.$ajax.post2(that.$const.replayURL, obj)
                  .then(res=>{
                    that.replay_content = ''
                    that.update_chat();
                    that.scrollToBottom()
                  })
        }

      },
      update_chat() {
          let that = this
          //判断是否是从其他页面跳转过来，并带来参数
          let obj;
          if (that.componentData.hasOwnProperty('obj')) {
              obj = that.componentData.obj;
          }

          that.$ajax.post2(that.$const.updateChatURL, obj)
                  .then(res => {
                      that.concatNewMsgList(res);
                      if (res.length > 0)
                          that.scrollToBottom()
                  })
      },
      concatNewMsgList(arr) {
        let newList = [];
        arr.forEach((item) => {
          if (
                  !this.dataList.find(
                          ({ createTime }) => item.createTime === createTime
                  )
          ) {
            newList.push(item);
          }
        });
        this.dataList = this.dataList.concat(newList);

      },
      //返回
      closeComponent(){
        let that = this;
        that.$parent.closeComponent(that.componentIndex)
        clearInterval(this.interval)
      },
      // 滚动到最新的一条消息
      scrollToBottom() {
        this.$refs.msgList.scrollTop = document.getElementById('msg-list').scrollHeight*1 + 90*1;

      },
    },
  };
</script>

<style lang="less" scoped>
  .chat-page-container {
    width: 100%;
    height: calc(100vh - 350px);
    display: flex;
    flex-direction: column;
    align-items: center;
    .msg-list {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      padding-right: 8px;
      border: 1px #c9c7c7 solid;
      border-radius: 5px;
      margin-bottom: 10px;
      padding-bottom: 70px;
    }

    .msg-item {
      flex-shrink: 0;
      width: 100%;
      display: flex;
      align-items: flex-start;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 4px;
        margin-right: 12px;
        object-fit: cover;
      }

      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .nickname {
          color: #666666;
          margin-bottom: 8px;
          font-size: 12px;
        }

        .text {
          display: flex;
          max-width: calc(100% - 40px - 12px);
          box-sizing: border-box;
          padding: 6px 12px;
          background: pink;
          border-radius: 4px;
        }
      }
    }

    .msg-item[isSlef="true"] {
      flex-direction: row-reverse;

      .avatar {
        margin-left: 12px;
        margin-right: 0;
      }

      .content {
        align-items: flex-end;
      }
    }
  }

  .textarea {
    float: left;
    width: 80%;
    height: 90px;
    border: 1px #9a9898 solid;
    border-radius: 5px;
    padding: 5px;
    > textarea {
      width: 100%;
      height: 80px;
      border: none;
      resize: none;
      outline: none;
    }
  }
</style>
