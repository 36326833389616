<template>
    <fragment>
        <a-tree
                checkable
                :tree-data="structure.config.values"
                :replaceFields="{children:'children', title:'label', key:'value' }"
                v-model:checkedKeys="structure.value"
                @check="emitData"
        />
        <span class="warning-class" v-if="showWarning" v-html="structure.warning"></span>
        <!--                v-model:checkedKeys="structure.value"-->
        <!--                checkable-->
        <!--                :selected-keys="selectedKeys"-->
        <!--                :tree-data="structure.config.values"-->
        <!--                @check="emitData"-->
        <!--                :replaceFields="{children:'children', title:'label', key:'value' }"-->
    </fragment>
</template>
<script>

    export default {
        name: 'LuckyTree',

        props: ['structure','showWarning'],
        data() {
            return {
                selectedKeys: [],
                checkedKeys:JSON.parse(JSON.stringify(this.structure.value)),
            };
        },
        created() {
            this.arrangeCheckedKeys(this.structure.config.values)
        },
        methods: {
            emitData(selectedKeys){
                this.selectedKeys = selectedKeys;
            },
            arrangeCheckedKeys(structureValues){
                if(structureValues.length > 0){
                    structureValues.forEach((item,index)=>{
                        let checkedItemValue = true
                        if(item.children){
                            checkedItemValue = this.arrangeCheckedKeys(item.children)
                        }
                        //处理需要修改的数组
                        let position = this.structure.value.findIndex(checkedValue => checkedValue === item.value)
                        if( position !== -1 && !checkedItemValue){
                            this.structure.value.splice(position,1)
                        }
                    })
                    //告诉父级，子级是否全部选中
                    return this.childCheckedAll(structureValues,this.checkedKeys)
                }
                return true
            },
            childCheckedAll(tmpArray,targetArray){
                let tmpValues = [];
                //将当前数组的所有值放到临时values
                tmpArray.forEach((item,index)=>{
                    tmpValues[index] = item.value
                })
                //判断临时数组和目标数组的交集是否等于临时数组长度
                if(targetArray.filter(function (v) { return tmpValues.indexOf(v) > -1}).length == tmpValues.length)
                    return true
                else{
                    return false
                }
            },
        },

    };
</script>
